import {type dictionary } from "./type";

const dictionary: dictionary = {
  ui: {
    searchPlaceholder: "ここで検索~",
    columnsHidden: "列を隠す",
    actions: {
      add: "追加",
      create: "作成",
      remove: "削除",
      update: "更新",
      open: "開く",
      upload: "アップロード",
      save: "保存",
      reset: "クリア",
      modify: "修正",
      cancel: "キャンセル",
      close: "閉じる",
      back: "戻る",
      filter: "フィルター",
      generateImage: "画像生成",
      swap: "入れ替え",
      checkInfo: "情報を確認",
      zoomIn: "拡大",
      zoomOut: "縮小",
    },
    nav: {
      home: "ホーム",
      mobs: "モンスター",
      skills: "スキル",
      equipments: "装備",
      crystals: "クリスタル",
      pets: "ペット",
      items: "消耗品",
      character: "キャラクター",
      simulator: "コンボ分析",
    },
    errorPage: {
      tips: "あなたは知識の荒野にいるが、クリックして戻ります",
    },
    settings: {
      title: "設定",
      userInterface: {
        title: "外観",
        isAnimationEnabled: {
          title: "アニメーションを有効にする",
          description:
            "すべてのページの遷移とアニメーション効果の持続時間に影響します。このコンフィグで制御されていないアニメーションがある場合は、ご報告ください。",
        },
        is3DbackgroundDisabled: {
          title: "3D背景を無効にする",
          description: "3D背景を無効にすると、大量の性能損失が発生しますが、推奨されません。",
        },
      },
      language: {
        title: "言語",
        selectedLanguage: {
          title: "言語を選択",
          description: "すべてのインターフェーステキストに影響しますが、データテキストは変更できません。",
          zhCN: "简体中文",
          zhTW: "繁体中文",
          enUS: "English",
          jaJP: "日本語",
        },
      },
      statusAndSync: {
        title: "状態と同期",
        restorePreviousStateOnStartup: {
          title: "起動時に前回の状態を復元",
          description: "まだ実装されていません。",
        },
        syncStateAcrossClients: {
          title: "すべてのクライアントの状態を同期",
          description:
            "この設定はユーザーがログインしている場合にのみ有効です。ログインしていない場合、クライアントには識別子がないため同期できません。",
        },
      },
      privacy: {
        title: "プライバシー",
        postVisibility: {
          title: "作品の可視性",
          description:
            "作品の可視性には以下が含まれます：キャラクター、モンスター、クリスタル、メイン武器、サブ武器、ボディアーマー、追加装備、特殊装備、ペット、スキル、消耗品、コンボ、アナライザー。",
          everyone: "全員に公開",
          friends: "フレンドのみに公開",
          onlyMe: "自分のみに公開",
        },
      },
      messages: {
        title: "メッセージ通知",
        notifyOnContentChange: {
          title: "以下の内容に変更があった場合に通知する",
          description: "まだ実装されていません。",
          notifyOnReferencedContentChange: "参照コンテンツに変更があった場合",
          notifyOnLike: "いいねを受け取った場合",
          notifyOnBookmark: "作品がブックマークされた場合",
        },
      },
      about: {
        title: "このアプリについて",
        description: {
          title: "説明",
          description: "まだ記述内容を決めていません。",
        },
        version: {
          title: "バージョン",
          description: "0.0.1-alpha",
        },
      },
    },
    index: {
      adventurer: "冒険者",
      goodMorning: "おはにゃ～ (=´ω｀=)",
      goodAfternoon: "こんにちは ヾ(=･ω･=)o",
      goodEvening: "こんばんは (。-ω-)zzz",
      nullSearchResultWarring: "関連コンテンツが見つかりません!!!∑(ﾟДﾟノ)ノ",
      nullSearchResultTips:
        "強くなる旅には困難が待ち受け、知識を求める道には障害物が散らばっています\nしかし、ここにはありません\n検索結果にないということは、存在しないということです",
    },
    mob: {
      pageTitle: "モンスター",
      table: {
        title: "モンスター",
        description: "ログイン後、自身でデータをアップロードできます。",
      },
      news: {
        title: "最新のアップデート",
      },
      augmented: "すべての星級データを表示",
      canNotModify: "システムによって生成され、修正不可",
      mobDegreeOfDifficulty: {
        0: "ゼロ星",
        1: "一星",
        2: "二星",
        3: "三星",
        4: "四星",
      },
      mobForm: {
        description:
          "固定ボスデータをアップロードする際は一星データを使用してください。システムが規則に従って他の星級データを自動的に計算します。",
      },
    },
    crystal: {
      pageTitle: "クリスタルテーブル",
      description: "開発中です。使用しないでください。",
      canNotModify: "システムによって生成され、修正不可",
      crystalForm: {
        description: "アララ",
      },
    },
    skill: {
      pageTitle: "スキル情報テーブル",
      description: "このページは開発中です。使用しないでください。",
    },
    simulator: {
      pageTitle: "プロセス計算機",
      description: "開発中です。使用しないでください。",
      modifiers: "補正項目",
      // dialogData: {
      //   selfName: "属性",
      //   lv: "レベル",
      //   mainWeapon: {
      //     selfName: "メイン武器",
      //     type: "メイン武器タイプ",
      //     baseAtk: "メイン武器基礎攻撃力",
      //     refinement: "メイン武器精錬値",
      //     stability: "メイン武器安定率",
      //   },
      //   subWeapon: {
      //     selfName: "サブ武器",
      //     type: "サブ武器タイプ",
      //     baseAtk: "サブ武器基礎攻撃力",
      //     refinement: "サブ武器精錬値",
      //     stability: "サブ武器安定率",
      //   },
      //   bodyArmor: {
      //     selfName: "ボディアーマー",
      //     type: "ボディアーマータイプ",
      //     baseDef: "ボディアーマー基礎防御力",
      //     refinement: "ボディアーマー精錬値",
      //   },
      //   str: "力",
      //   int: "知力",
      //   vit: "体力",
      //   agi: "敏捷",
      //   dex: "器用",
      //   luk: "運",
      //   cri: "クリティカル",
      //   tec: "テクニック",
      //   men: "異常耐性",
      //   pPie: "物理貫通",
      //   mPie: "魔法貫通",
      //   pStab: "物理安定",
      //   sDis: "近距離威力",
      //   lDis: "遠距離威力",
      //   crC: "魔法クリティカル変換率",
      //   cdC: "魔法クリティカルダメージ変換率",
      //   weaponPatkT: "武器攻撃変換率（物理）",
      //   weaponMatkT: "武器攻撃変換率（魔法）",
      //   uAtk: "抜刀攻撃",
      //   stro: {
      //     Light: "",
      //     Normal: "",
      //     Dark: "",
      //     Water: "",
      //     Fire: "",
      //     Earth: "",
      //     Wind: "",
      //     selfName: "攻撃タイプ",
      //   },
      //   total: "総ダメージ上昇",
      //   final: "最終ダメージ上昇",
      //   am: "行動速度",
      //   cm: "詠唱短縮",
      //   aggro: "ヘイト倍率",
      //   maxHp: "最大HP",
      //   maxMp: "最大MP",
      //   pCr: "物理クリティカル",
      //   pCd: "物理クリティカルダメージ",
      //   mainWeaponAtk: "メイン武器攻撃力",
      //   subWeaponAtk: "サブ武器攻撃力",
      //   weaponAtk: "武器攻撃力",
      //   pAtk: "物理攻撃",
      //   mAtk: "魔法攻撃",
      //   aspd: "攻撃速度",
      //   cspd: "詠唱速度",
      //   ampr: "攻撃MP回復",
      //   hp: "現在HP",
      //   mp: "現在MP",
      //   name: "名前",
      //   pDef: "物理防御",
      //   pRes: "物理耐性",
      //   mDef: "魔法防御",
      //   mRes: "魔法耐性",
      //   cRes: "クリティカル耐性",
      //   anticipate: "先読み",
      //   index: "インデックス",
      //   skillEffectType: "スキル効果タイプ",
      //   actionFixedDuration: "固定アクションフレーム",
      //   actionModifiableDuration: "加速可能アクションフレーム",
      //   skillActionFrames: "アクション総フレーム",
      //   chantingFixedDuration: "固定詠唱時間",
      //   chantingModifiableDuration: "加速可能詠唱時間",
      //   skillChantingFrames: "詠唱総時間",
      //   chargingFixedDuration: "固定チャージ時間",
      //   chargingModifiableDuration: "加速可能チャージ時間",
      //   skillChargingFrames: "チャージ総時間",
      //   skillDuration: "スキル総所要時間",
      //   skillStartupFrames: "スキル発動前隙",
      //   vMatk: "有効魔法攻撃力",
      //   vPatk: "有効物理攻撃力",
      // },
      actualValue: "実際値",
      baseValue: "基本値",
      staticModifiers: "常時補正",
      dynamicModifiers: "一時補正",
      simulatorPage: {
        mobsConfig: {
          title: "モンスター設定",
        },
        teamConfig: {
          title: "チーム設定"
        }
      }
    },
    character: {
      pageTitle: "キャラクターテーブル",
      description: "このページは開発中です。使用しないでください。",
    },
  },
  
  enums: {
    MobType: {
      Boss: "定点王",
      MiniBoss: "野王",
      Mob: "小怪",
      selfName: "怪物类型",
    },
    Element: {
      Normal: "无属性",
      Dark: "暗属性",
      Earth: "地属性",
      Fire: "火属性",
      Light: "光属性",
      Water: "水属性",
      Wind: "风属性",
      selfName: "元素类型",
    },
    PersonalityType: {
      None: "无",
      Luk: "幸运",
      Cri: "暴击",
      Tec: "技巧",
      Men: "异抗",
      selfName: "特殊能力值类型",
    },
    MainWeaponType: {
      OneHandSword: "单手剑",
      TwoHandSword: "双手剑",
      Bow: "弓",
      Rod: "法杖",
      Magictool: "魔导具",
      Knuckle: "拳套",
      Halberd: "旋风枪",
      Katana: "拔刀剑",
      Bowgun: "弩",
      selfName: "主武器类型",
    },
    SubWeaponType: {
      Arrow: "箭矢",
      ShortSword: "小刀",
      NinjutsuScroll: "忍术卷轴",
      Shield: "盾牌",
      selfName: "副武器类型",
    },
    ArmorType: {
      Normal: "一般",
      Light: "轻化",
      Heavy: "重化",
      selfName: "防具类型",
    },
    CrystalType: {
      NormalCrystal: "通用锻晶",
      WeaponCrystal: "武器锻晶",
      ArmorCrystal: "防具锻晶",
      AddEquipCrystal: "追加锻晶",
      SpecialCrystal: "特殊锻晶",
      selfName: "锻晶类型",
    },
    SkillTreeType: {
      BladeSkill: "剑术技能",
      ShootSkill: "射击技能",
      MagicSkill: "魔法技能",
      MarshallSkill: "格斗技能",
      DualSwordSkill: "双剑技能",
      HalberdSkill: "斧枪技能",
      MononofuSkill: "武士技能",
      CrusherSkill: "粉碎者技能",
      FeatheringSkill: "灵魂技能",
      GuardSkill: "格挡技能",
      ShieldSkill: "护盾技能",
      KnifeSkill: "小刀技能",
      KnightSkill: "骑士技能",
      HunterSkill: "狩猎技能",
      PriestSkill: "祭司技能",
      AssassinSkill: "暗杀技能",
      WizardSkill: "巫师技能",
      //
      SupportSkill: "辅助技能",
      BattleSkill: "好战分子",
      SurvivalSkill: "生存本能",
      //
      SmithSkill: "锻冶大师",
      AlchemySkill: "炼金术士",
      TamerSkill: "驯兽天分",
      //
      DarkPowerSkill: "暗黑之力",
      MagicBladeSkill: "魔剑技能",
      DancerSkill: "舞者技能",
      MinstrelSkill: "诗人技能",
      BareHandSkill: "空手技能",
      NinjaSkill: "忍者技能",
      PartisanSkill: "游击队技能",
      //
      LuckSkill: "",
      MerchantSkill: "商人技能",
      PetSkill: "宠物技能",
      selfName: "技能树名称",
    },
    UserRole: {
      USER: "常规用户",
      ADMIN: "管理员",
      selfName: "用户角色",
    },
    YieldType: {
      ImmediateEffect: "即时效果（仅作用一次）",
      PersistentEffect: "持续型效果（在被删除前，一直有效）",
      selfName: "效果类型",
    },
    SkillChargingType: {
      Chanting: "咏唱",
      Reservoir: "蓄力",
      selfName: "技能额外动作类型",
    },
    ModifierType: {
      selfName: "",
      str: "",
      int: "",
      vit: "",
      agi: "",
      dex: "",
      luk: "",
      tec: "",
      men: "",
      cri: "",
      maxMp: "",
      mp: "",
      aggro: "",
      weaponRange: "",
      hpRegen: "",
      mpRegen: "",
      mpAtkRegen: "",
      physicalAtk: "",
      magicalAtk: "",
      weaponAtk: "",
      unsheatheAtk: "",
      physicalPierce: "",
      magicalPierce: "",
      criticalRate: "",
      criticalDamage: "",
      magicCrtConversionRate: "",
      magicCrtDamageConversionRate: "",
      magicCriticalRate: "",
      magicCriticalDamage: "",
      shortRangeDamage: "",
      longRangeDamage: "",
      strongerAgainstNetural: "",
      strongerAgainstLight: "",
      strongerAgainstDark: "",
      strongerAgainstWater: "",
      strongerAgainstFire: "",
      strongerAgainstEarth: "",
      strongerAgainstWind: "",
      totalDamage: "",
      finalDamage: "",
      stability: "",
      magicStability: "",
      accuracy: "",
      additionalPhysics: "",
      additionalMagic: "",
      anticipate: "",
      guardBreak: "",
      reflect: "",
      absolutaAccuracy: "",
      atkUpStr: "",
      atkUpInt: "",
      atkUpVit: "",
      atkUpAgi: "",
      atkUpDex: "",
      matkUpStr: "",
      matkUpInt: "",
      matkUpVit: "",
      matkUpAgi: "",
      matkUpDex: "",
      atkDownStr: "",
      atkDownInt: "",
      atkDownVit: "",
      atkDownAgi: "",
      atkDownDex: "",
      matkDownStr: "",
      matkDownInt: "",
      matkDownVit: "",
      matkDownAgi: "",
      matkDownDex: "",
      maxHp: "",
      hp: "",
      physicalDef: "",
      magicalDef: "",
      physicalResistance: "",
      magicalResistance: "",
      neutralResistance: "",
      lightResistance: "",
      darkResistance: "",
      waterResistance: "",
      fireResistance: "",
      earthResistance: "",
      windResistance: "",
      dodge: "",
      ailmentResistance: "",
      baseGuardPower: "",
      guardPower: "",
      baseGuardRecharge: "",
      guardRecharge: "",
      evasionRecharge: "",
      physicalBarrier: "",
      magicalBarrier: "",
      fractionalBarrier: "",
      barrierCooldown: "",
      reduceDmgFloor: "",
      reduceDmgMeteor: "",
      reduceDmgPlayerEpicenter: "",
      reduceDmgFoeEpicenter: "",
      reduceDmgBowling: "",
      reduceDmgBullet: "",
      reduceDmgStraightLine: "",
      reduceDmgCharge: "",
      absoluteDodge: "",
      aspd: "",
      asrd: "",
      cspd: "",
      csrd: "",
      mspd: "",
      dropRate: "",
      reviveTime: "",
      flinchUnavailable: "",
      tumbleUnavailable: "",
      stunUnavailable: "",
      invincibleAid: "",
      expRate: "",
      petExp: "",
      itemCooldown: "",
      recoilDamage: "",
      gemPowderDrop: ""
    },
    SkillTargetType: {
      selfName: "",
      None: "",
      Self: "",
      Player: "",
      Enemy: ""
    },
    DurationType: {
      selfName: "",
      FRAME: "",
      SKILL: "",
      UNLIMITED: ""
    },
    MobDifficultyFlag: {
      selfName: "",
      Easy: "",
      Normal: "",
      Hard: "",
      Lunatic: "",
      Ultimate: ""
    },
    MobDamageType: {
      selfName: "",
      Physics: "",
      Magic: "",
      CurrentRate: "",
      MaxRate: ""
    },
    AddressType: {
      selfName: "",
      Normal: "",
      Limited: ""
    },
    MaterialType: {
      selfName: "",
      Magic: "",
      Metal: "",
      Cloth: "",
      Beast: "",
      Wood: "",
      Drug: ""
    },
    PartBreakReward: {
      selfName: "",
      None: "",
      CanDrop: "",
      DropUp: ""
    },
    MobPart: {
      selfName: "",
      A: "",
      B: "",
      C: ""
    },
    AcquisitionMethodType: {
      selfName: "",
      Drop: "",
      Craft: ""
    },
    SkillDistanceResistType: {
      selfName: "",
      None: "",
      Long: "",
      Short: "",
      Both: ""
    },
    PetPersonaType: {
      selfName: "",
      Fervent: "",
      Intelligent: "",
      Mild: "",
      Swift: "",
      Justice: "",
      Devoted: "",
      Impulsive: "",
      Calm: "",
      Sly: "",
      Timid: "",
      Brave: "",
      Active: "",
      Sturdy: "",
      Steady: "",
      Max: ""
    },
    PetType: {
      selfName: "",
      AllTrades: "",
      PhysicalAttack: "",
      MagicAttack: "",
      PhysicalDefense: "",
      MagicDefensem: "",
      Avoidance: "",
      Hit: "",
      SkillsEnhancement: "",
      Genius: ""
    },
    MercenaryType: {
      selfName: "",
      Tank: "",
      Dps: ""
    },
    MercenarySkillType: {
      selfName: "",
      Active: "",
      Passive: ""
    },
    Visibility: {
      selfName: "",
      Public: "",
      Private: ""
    },
    AccountType: {
      selfName: "",
      Admin: "",
      User: ""
    },
    WeaponType: {
      selfName: "",
      OneHandSword: "",
      TwoHandSword: "",
      Bow: "",
      Bowgun: "",
      Rod: "",
      Magictool: "",
      Knuckle: "",
      Halberd: "",
      Katana: "",
      Arrow: "",
      ShortSword: "",
      NinjutsuScroll: "",
      Shield: ""
    },
    EquipType: {
      selfName: "",
      OneHandSword: "",
      TwoHandSword: "",
      Bow: "",
      Bowgun: "",
      Rod: "",
      Magictool: "",
      Knuckle: "",
      Halberd: "",
      Katana: "",
      Arrow: "",
      ShortSword: "",
      NinjutsuScroll: "",
      Shield: "",
      Armor: "",
      AddEquip: "",
      SpeEquip: ""
    },
    ItemType: {
      selfName: "",
      OneHandSword: "",
      TwoHandSword: "",
      Bow: "",
      Bowgun: "",
      Rod: "",
      Magictool: "",
      Knuckle: "",
      Halberd: "",
      Katana: "",
      Arrow: "",
      ShortSword: "",
      NinjutsuScroll: "",
      Shield: "",
      Armor: "",
      AddEquip: "",
      SpeEquip: "",
      NormalCrystal: "",
      WeaponCrystal: "",
      ArmorCrystal: "",
      AddEquipCrystal: "",
      SpecialCrystal: "",
      Metal: "",
      Cloth: "",
      Beast: "",
      Wood: "",
      Drug: "",
      Magic: "",
      MaxHp: "",
      MaxMp: "",
      pAtk: "",
      mAtk: "",
      Aspd: "",
      Cspd: "",
      Hit: "",
      Flee: "",
      EleStro: "",
      EleRes: "",
      pRes: "",
      mRes: ""
    },
    TaskRewardType: {
      selfName: "",
      OneHandSword: "",
      TwoHandSword: "",
      Bow: "",
      Bowgun: "",
      Rod: "",
      Magictool: "",
      Knuckle: "",
      Halberd: "",
      Katana: "",
      Arrow: "",
      ShortSword: "",
      NinjutsuScroll: "",
      Shield: "",
      Armor: "",
      AddEquip: "",
      SpeEquip: "",
      NormalCrystal: "",
      WeaponCrystal: "",
      ArmorCrystal: "",
      AddEquipCrystal: "",
      SpecialCrystal: "",
      Exp: "",
      Money: "",
      Metal: "",
      Cloth: "",
      Beast: "",
      Wood: "",
      Drug: "",
      Magic: "",
      MaxHp: "",
      MaxMp: "",
      pAtk: "",
      mAtk: "",
      Aspd: "",
      Cspd: "",
      Hit: "",
      Flee: "",
      EleStro: "",
      EleRes: "",
      pRes: "",
      mRes: ""
    },
    AbnormalType: {
      selfName: "",
      MagicFlinch: "",
      None: "",
      Flinch: "",
      Tumble: "",
      Stun: "",
      KnockBack: "",
      Poison: "",
      PoisonLevel1: "",
      PoisonLevel2: "",
      Paralysis: "",
      Blindness: "",
      Ignition: "",
      Freeze: "",
      Breaking: "",
      Slow: "",
      Stop: "",
      Fear: "",
      Dizzy: "",
      Weak: "",
      Collapse: "",
      Confusion: "",
      Silent: "",
      Bleed: "",
      Sleep: "",
      Rage: "",
      Tiredness: "",
      Blessing: "",
      SystemInvincibility: "",
      BestState: "",
      Invincibility: "",
      Suction: "",
      Taming: "",
      Curse: "",
      Flash: "",
      Runaway: "",
      MagicalExplosion: "",
      Sick: "",
      Malgravity: "",
      Dispel: "",
      Inversion: "",
      Mineralization: "",
      NoTools: "",
      Enhance: "",
      ComboInvincibility: "",
      DeathTorqueShot: "",
      SystemAddHate: "",
      Recovery: ""
    },
    SkillAttackType: {
      selfName: "",
      None: "",
      Physical: "",
      Magic: "",
      SkillNormal: ""
    },
    SkillComboType: {
      selfName: "",
      None: "",
      Start: "",
      Rengeki: "",
      ThirdEye: "",
      Filling: "",
      Quick: "",
      HardHit: "",
      Tenacity: "",
      Invincible: "",
      BloodSucking: "",
      Tough: "",
      AMomentaryWalk: "",
      Reflection: "",
      Illusion: "",
      Max: ""
    }
  },
};

export default dictionary;
