import { type dictionary } from "./type";
const dictionary: dictionary = {
  ui: {
    searchPlaceholder: "這裡是搜尋框~",
    columnsHidden: "隱藏列",
    actions: {
      add: "新增",
      create: "建立",
      remove: "刪除",
      update: "更新",
      open: "開啟",
      upload: "上傳",
      save: "儲存",
      reset: "清空",
      modify: "修改",
      cancel: "取消",
      close: "關閉",
      back: "返回",
      filter: "過濾",
      generateImage: "生成圖片",
      swap: "替換",
      checkInfo: "查看詳情",
      zoomIn: "放大",
      zoomOut: "縮小",
    },
    nav: {
      home: "首頁",
      mobs: "怪物",
      skills: "技能",
      equipments: "裝備",
      crystals: "鍛晶",
      pets: "寵物",
      items: "消耗品",
      character: "角色配置",
      simulator: "連擊分析",
    },
    errorPage: {
      tips: "你來到了沒有知識的荒原~，點擊屏幕返回",
    },
    settings: {
      title: "設定",
      userInterface: {
        title: "外觀",
        isAnimationEnabled: {
          title: "是否開啟動畫",
          description: "將影響所有頁面的過渡和動畫效果持續時間。",
        },
        is3DbackgroundDisabled: {
          title: "是否禁用3D背景",
          description: "可能會產生大量性能損耗，不推薦開啟。",
        }
      },
      language: {
        title: "語言",
        selectedLanguage: {
          title: "選擇語言",
          description: "影響所有的介面文本，但是無法改變資料類文本。",
          zhCN: "簡體中文",
          zhTW: "繁體中文",
          enUS: "English",
          jaJP: "日本語",
        },
      },
      statusAndSync: {
        title: "狀態與同步",
        restorePreviousStateOnStartup: {
          title: "啟動時恢復上一次的狀態",
          description: "暫未實現。",
        },
        syncStateAcrossClients: {
          title: "同步所有客戶端狀態",
          description: "此設定僅當使用者登入時生效，未登入時客戶端不具有身分標識，無法同步。",
        },
      },
      privacy: {
        title: "隱私",
        postVisibility: {
          title: "作品可見性",
          description:
            "作品可見性包括：角色、怪物、鍛晶、主武器、副武器、身體裝備、追加裝備、特殊裝備、寵物、技能、消耗品、連擊、分析器。",
          everyone: "所有人可見",
          friends: "僅好友可見",
          onlyMe: "僅自己可見",
        },
      },
      messages: {
        title: "訊息通知",
        notifyOnContentChange: {
          title: "以下內容發生變化時通知我",
          description: "暫未實現。",
          notifyOnReferencedContentChange: "引用內容改變時",
          notifyOnLike: "收到讚時",
          notifyOnBookmark: "作品被收藏時",
        },
      },
      about: {
        title: "關於此應用",
        description: {
          title: "描述",
          description: "沒想好怎麼寫。",
        },
        version: {
          title: "版本",
          description: "0.0.1-alpha",
        },
      },
    },
    index: {
      adventurer: "冒險者",
      goodMorning: "哦哈喵~ (=´ω｀=)",
      goodAfternoon: "下午好ヾ(=･ω･=)o",
      goodEvening: "晚上好(.-ω-)zzz",
      nullSearchResultWarring: "沒有找到相關內容!!!∑(ﾟДﾟノ)ノ",
      nullSearchResultTips: "變強之旅總有艱險阻道，求知路上不免遍佈荊棘\n但是這裡沒有\n搜尋結果裡沒有就是沒有",
    },
    mob: {
      pageTitle: "怪物",
      table: {
        title: "怪物資訊表",
        description: "不是所有怪物一開始就是怪物，也不是所有怪物看起來都像怪物。",
      },
      news: {
        title: "最近更新",
      },
      augmented: "是否展示全部星級資料",
      canNotModify: "系統生成，不可修改",
      mobDegreeOfDifficulty: {
        0: "零星",
        1: "一星",
        2: "二星",
        3: "三星",
        4: "四星",
      },
      mobForm: {
        description: "上傳定點boss資料時請使用一星數據，系統將以規則自動計算其餘星級資料。",
      },
    },
    crystal: {
      pageTitle: "鍛晶表",
      description: "正在開發中，請勿使用。",
      canNotModify: "系統生成，不可修改",
      crystalForm: {
        description: "阿拉啦",
      },
    },
    skill: {
      pageTitle: "技能資訊表",
      description: "此頁面正在開發中，請勿使用",
    },
    simulator: {
      pageTitle: "流程計算器",
      description: "正在開發中，請勿使用",
      modifiers: "加成項",
      // dialogData: {
      //   selfName: "属性",
      //   lv: "等級",
      //   mainWeapon: {
      //     type: "主武器型別",
      //     baseAtk: "主武器基礎攻擊力",
      //     refinement: "主武器精煉值",
      //     stability: "主武器穩定率",
      //     selfName: "主武器",
      //   },
      //   subWeapon: {
      //     type: "副武器型別",
      //     baseAtk: "副武器基礎攻擊力",
      //     refinement: "副武器精煉值",
      //     stability: "副武器穩定率",
      //     selfName: "副武器",
      //   },
      //   bodyArmor: {
      //     type: "身體裝備類型",
      //     baseDef: "身體裝備基礎防禦力",
      //     refinement: "身體裝備精煉值",
      //     selfName: "身體裝備",
      //   },
      //   str: "力量",
      //   int: "智力",
      //   vit: "耐力",
      //   agi: "敏捷",
      //   dex: "靈巧",
      //   luk: "幸運",
      //   cri: "爆擊",
      //   tec: "技巧",
      //   men: "異抗",
      //   pPie: "物理貫穿",
      //   mPie: "魔法貫穿",
      //   pStab: "物理穩定",
      //   sDis: "近距離威力",
      //   lDis: "遠距離威力",
      //   crC: "法術爆擊轉換率",
      //   cdC: "法術爆傷轉換率",
      //   weaponPatkT: "武器攻擊轉換率（物理）",
      //   weaponMatkT: "武器攻擊轉換率（魔法）",
      //   uAtk: "拔刀攻擊",
      //   stro: {
      //     Light: "对光属性增强",
      //     Normal: "对無属性增强",
      //     Dark: "对暗属性增强",
      //     Water: "对水属性增强",
      //     Fire: "对火属性增强",
      //     Earth: "对土属性增强",
      //     Wind: "对風属性增强",
      //     selfName: "对属增強",
      //   },
      //   total: "總傷害提升",
      //   final: "最終傷害提升",
      //   am: "行動速度",
      //   cm: "詠唱縮減",
      //   aggro: "仇恨值倍率",
      //   maxHp: "生命值上限",
      //   maxMp: "法力值上限",
      //   pCr: "物理暴擊",
      //   pCd: "物理爆傷",
      //   mainWeaponAtk: "主武器攻擊力",
      //   subWeaponAtk: "副武器攻擊力",
      //   weaponAtk: "武器攻擊力",
      //   pAtk: "物理攻擊",
      //   mAtk: "魔法攻擊",
      //   aspd: "攻擊速度",
      //   cspd: "詠唱速度",
      //   ampr: "攻回",
      //   hp: "當前生命值",
      //   mp: "當前法力值",
      //   name: "名稱",
      //   pDef: "物理防禦",
      //   pRes: "物理抗性",
      //   mDef: "魔法防禦",
      //   mRes: "魔法抗性",
      //   cRes: "暴擊抗性",
      //   anticipate: "看穿",

      //   index: "序號",
      //   skillEffectType: "讀取條類型",
      //   actionFixedDuration: "動畫固定畫面",
      //   actionModifiableDuration: "動畫可加速影格",
      //   skillActionFrames: "動畫長度總值",
      //   chantingFixedDuration: "固定詠唱時間",
      //   chantingModifiableDuration: "可加速詠唱時長",
      //   skillChantingFrames: "詠唱時長總值",
      //   chargingFixedDuration: "固定蓄力時長",
      //   chargingModifiableDuration: "可加速蓄力時長",
      //   skillChargingFrames: "蓄力長度總值",
      //   skillDuration: "技能總耗時",
      //   skillStartupFrames: "技能前搖",
      //   vMatk: "有效攻擊力（魔法）",
      //   vPatk: "有效物攻（物理）",
      // },
      actualValue: "實際值",
      baseValue: "基礎值",
      staticModifiers: "常態加成",
      dynamicModifiers: "暫時加成",
      simulatorPage: {
        mobsConfig: {
          title: "目標怪物",
        },
        teamConfig: {
          title: "隊伍配置"
        }
      }
    },
    character: {
      pageTitle: "機體表",
      description: "此頁面正在開發中，請勿使用",
    },
  },
  enums: {
    MobType: {
      Boss: "定点王",
      MiniBoss: "野王",
      Mob: "小怪",
      selfName: "怪物类型",
    },
    Element: {
      Normal: "无属性",
      Dark: "暗属性",
      Earth: "地属性",
      Fire: "火属性",
      Light: "光属性",
      Water: "水属性",
      Wind: "风属性",
      selfName: "元素类型",
    },
    PersonalityType: {
      None: "无",
      Luk: "幸运",
      Cri: "暴击",
      Tec: "技巧",
      Men: "异抗",
      selfName: "特殊能力值类型",
    },
    MainWeaponType: {
      OneHandSword: "单手剑",
      TwoHandSword: "双手剑",
      Bow: "弓",
      Rod: "法杖",
      Magictool: "魔导具",
      Knuckle: "拳套",
      Halberd: "旋风枪",
      Katana: "拔刀剑",
      Bowgun: "弩",
      selfName: "主武器类型",
    },
    SubWeaponType: {
      Arrow: "箭矢",
      ShortSword: "小刀",
      NinjutsuScroll: "忍术卷轴",
      Shield: "盾牌",
      selfName: "副武器类型",
    },
    ArmorType: {
      Normal: "一般",
      Light: "轻化",
      Heavy: "重化",
      selfName: "防具类型",
    },
    CrystalType: {
      NormalCrystal: "通用锻晶",
      WeaponCrystal: "武器锻晶",
      ArmorCrystal: "防具锻晶",
      AddEquipCrystal: "追加锻晶",
      SpecialCrystal: "特殊锻晶",
      PowerUpNormalCrystal: "通用强化锻晶",
      PowerUpWeaponCrystal: "武器强化锻晶",
      PowerUpArmorCrystal: "防具强化锻晶",
      PowerUpAddEquipCrystal: "追加强化锻晶",
      PowerUpSpecialCrystal: "特殊强化锻晶",
      selfName: "锻晶类型",
    },
    SkillTreeType: {
      BladeSkill: "剑术技能",
      ShootSkill: "射击技能",
      MagicSkill: "魔法技能",
      MarshallSkill: "格斗技能",
      DualSwordSkill: "双剑技能",
      HalberdSkill: "斧枪技能",
      MononofuSkill: "武士技能",
      CrusherSkill: "粉碎者技能",
      FeatheringSkill: "灵魂技能",
      GuardSkill: "格挡技能",
      ShieldSkill: "护盾技能",
      KnifeSkill: "小刀技能",
      KnightSkill: "骑士技能",
      HunterSkill: "狩猎技能",
      PriestSkill: "祭司技能",
      AssassinSkill: "暗杀技能",
      WizardSkill: "巫师技能",
      //
      SupportSkill: "辅助技能",
      BattleSkill: "好战分子",
      SurvivalSkill: "生存本能",
      //
      SmithSkill: "锻冶大师",
      AlchemySkill: "炼金术士",
      TamerSkill: "驯兽天分",
      //
      DarkPowerSkill: "暗黑之力",
      MagicBladeSkill: "魔剑技能",
      DancerSkill: "舞者技能",
      MinstrelSkill: "诗人技能",
      BareHandSkill: "空手技能",
      NinjaSkill: "忍者技能",
      PartisanSkill: "游击队技能",
      //
      LuckSkill: "",
      MerchantSkill: "商人技能",
      PetSkill: "宠物技能",
      selfName: "技能树名称",
    },
    UserRole: {
      USER: "常规用户",
      ADMIN: "管理员",
      selfName: "用户角色",
    },
    YieldType: {
      ImmediateEffect: "即时效果（仅作用一次）",
      PersistentEffect: "持续型效果（在被删除前，一直有效）",
      selfName: "效果类型",
    },
    SkillChargingType: {
      Chanting: "咏唱",
      Reservoir: "蓄力",
      selfName: "技能额外动作类型",
    },
    ModifierType: {
      selfName: "",
      str: "",
      int: "",
      vit: "",
      agi: "",
      dex: "",
      luk: "",
      tec: "",
      men: "",
      cri: "",
      maxMp: "",
      mp: "",
      aggro: "",
      weaponRange: "",
      hpRegen: "",
      mpRegen: "",
      mpAtkRegen: "",
      physicalAtk: "",
      magicalAtk: "",
      weaponAtk: "",
      unsheatheAtk: "",
      physicalPierce: "",
      magicalPierce: "",
      criticalRate: "",
      criticalDamage: "",
      magicCrtConversionRate: "",
      magicCrtDamageConversionRate: "",
      magicCriticalRate: "",
      magicCriticalDamage: "",
      shortRangeDamage: "",
      longRangeDamage: "",
      strongerAgainstNetural: "",
      strongerAgainstLight: "",
      strongerAgainstDark: "",
      strongerAgainstWater: "",
      strongerAgainstFire: "",
      strongerAgainstEarth: "",
      strongerAgainstWind: "",
      totalDamage: "",
      finalDamage: "",
      stability: "",
      magicStability: "",
      accuracy: "",
      additionalPhysics: "",
      additionalMagic: "",
      anticipate: "",
      guardBreak: "",
      reflect: "",
      absolutaAccuracy: "",
      atkUpStr: "",
      atkUpInt: "",
      atkUpVit: "",
      atkUpAgi: "",
      atkUpDex: "",
      matkUpStr: "",
      matkUpInt: "",
      matkUpVit: "",
      matkUpAgi: "",
      matkUpDex: "",
      atkDownStr: "",
      atkDownInt: "",
      atkDownVit: "",
      atkDownAgi: "",
      atkDownDex: "",
      matkDownStr: "",
      matkDownInt: "",
      matkDownVit: "",
      matkDownAgi: "",
      matkDownDex: "",
      maxHp: "",
      hp: "",
      physicalDef: "",
      magicalDef: "",
      physicalResistance: "",
      magicalResistance: "",
      neutralResistance: "",
      lightResistance: "",
      darkResistance: "",
      waterResistance: "",
      fireResistance: "",
      earthResistance: "",
      windResistance: "",
      dodge: "",
      ailmentResistance: "",
      baseGuardPower: "",
      guardPower: "",
      baseGuardRecharge: "",
      guardRecharge: "",
      evasionRecharge: "",
      physicalBarrier: "",
      magicalBarrier: "",
      fractionalBarrier: "",
      barrierCooldown: "",
      reduceDmgFloor: "",
      reduceDmgMeteor: "",
      reduceDmgPlayerEpicenter: "",
      reduceDmgFoeEpicenter: "",
      reduceDmgBowling: "",
      reduceDmgBullet: "",
      reduceDmgStraightLine: "",
      reduceDmgCharge: "",
      absoluteDodge: "",
      aspd: "",
      asrd: "",
      cspd: "",
      csrd: "",
      mspd: "",
      dropRate: "",
      reviveTime: "",
      flinchUnavailable: "",
      tumbleUnavailable: "",
      stunUnavailable: "",
      invincibleAid: "",
      expRate: "",
      petExp: "",
      itemCooldown: "",
      recoilDamage: "",
      gemPowderDrop: ""
    },
    SkillTargetType: {
      selfName: "",
      None: "",
      Self: "",
      Player: "",
      Enemy: ""
    },
    DurationType: {
      selfName: "",
      FRAME: "",
      SKILL: "",
      UNLIMITED: ""
    },
    MobDifficultyFlag: {
      selfName: "",
      Easy: "",
      Normal: "",
      Hard: "",
      Lunatic: "",
      Ultimate: ""
    },
    MobDamageType: {
      selfName: "",
      Physics: "",
      Magic: "",
      CurrentRate: "",
      MaxRate: ""
    },
    AddressType: {
      selfName: "",
      Normal: "",
      Limited: ""
    },
    MaterialType: {
      selfName: "",
      Magic: "",
      Metal: "",
      Cloth: "",
      Beast: "",
      Wood: "",
      Drug: ""
    },
    PartBreakReward: {
      selfName: "",
      None: "",
      CanDrop: "",
      DropUp: ""
    },
    MobPart: {
      selfName: "",
      A: "",
      B: "",
      C: ""
    },
    AcquisitionMethodType: {
      selfName: "",
      Drop: "",
      Craft: ""
    },
    SkillDistanceResistType: {
      selfName: "",
      None: "",
      Long: "",
      Short: "",
      Both: ""
    },
    PetPersonaType: {
      selfName: "",
      Fervent: "",
      Intelligent: "",
      Mild: "",
      Swift: "",
      Justice: "",
      Devoted: "",
      Impulsive: "",
      Calm: "",
      Sly: "",
      Timid: "",
      Brave: "",
      Active: "",
      Sturdy: "",
      Steady: "",
      Max: ""
    },
    PetType: {
      selfName: "",
      AllTrades: "",
      PhysicalAttack: "",
      MagicAttack: "",
      PhysicalDefense: "",
      MagicDefensem: "",
      Avoidance: "",
      Hit: "",
      SkillsEnhancement: "",
      Genius: ""
    },
    MercenaryType: {
      selfName: "",
      Tank: "",
      Dps: ""
    },
    MercenarySkillType: {
      selfName: "",
      Active: "",
      Passive: ""
    },
    Visibility: {
      selfName: "",
      Public: "",
      Private: ""
    },
    AccountType: {
      selfName: "",
      Admin: "",
      User: ""
    },
    WeaponType: {
      selfName: "",
      OneHandSword: "",
      TwoHandSword: "",
      Bow: "",
      Bowgun: "",
      Rod: "",
      Magictool: "",
      Knuckle: "",
      Halberd: "",
      Katana: "",
      Arrow: "",
      ShortSword: "",
      NinjutsuScroll: "",
      Shield: ""
    },
    EquipType: {
      selfName: "",
      OneHandSword: "",
      TwoHandSword: "",
      Bow: "",
      Bowgun: "",
      Rod: "",
      Magictool: "",
      Knuckle: "",
      Halberd: "",
      Katana: "",
      Arrow: "",
      ShortSword: "",
      NinjutsuScroll: "",
      Shield: "",
      Armor: "",
      AddEquip: "",
      SpecialEquip: ""
    },
    ItemType: {
      selfName: "",
      OneHandSword: "",
      TwoHandSword: "",
      Bow: "",
      Bowgun: "",
      Rod: "",
      Magictool: "",
      Knuckle: "",
      Halberd: "",
      Katana: "",
      Arrow: "",
      ShortSword: "",
      NinjutsuScroll: "",
      Shield: "",
      Armor: "",
      AddEquip: "",
      SpecialEquip: "",
      NormalCrystal: "",
      WeaponCrystal: "",
      ArmorCrystal: "",
      AddEquipCrystal: "",
      SpecialCrystal: "",
      PowerUpNormalCrystal: "",
      PowerUpWeaponCrystal: "",
      PowerUpArmorCrystal: "",
      PowerUpAddEquipCrystal: "",
      PowerUpSpecialCrystal: ""
    },
    TaskRewardType: {
      selfName: "",
      OneHandSword: "",
      TwoHandSword: "",
      Bow: "",
      Bowgun: "",
      Rod: "",
      Magictool: "",
      Knuckle: "",
      Halberd: "",
      Katana: "",
      Arrow: "",
      ShortSword: "",
      NinjutsuScroll: "",
      Shield: "",
      Armor: "",
      AddEquip: "",
      SpecialEquip: "",
      NormalCrystal: "",
      WeaponCrystal: "",
      ArmorCrystal: "",
      AddEquipCrystal: "",
      SpecialCrystal: "",
      PowerUpNormalCrystal: "",
      PowerUpWeaponCrystal: "",
      PowerUpArmorCrystal: "",
      PowerUpAddEquipCrystal: "",
      PowerUpSpecialCrystal: "",
      Exp: "",
      Money: ""
    },
    AbnormalType: {
      selfName: "",
      MagicFlinch: "",
      None: "",
      Flinch: "",
      Tumble: "",
      Stun: "",
      KnockBack: "",
      Poison: "",
      PoisonLevel1: "",
      PoisonLevel2: "",
      Paralysis: "",
      Blindness: "",
      Ignition: "",
      Freeze: "",
      Breaking: "",
      Slow: "",
      Stop: "",
      Fear: "",
      Dizzy: "",
      Weak: "",
      Collapse: "",
      Confusion: "",
      Silent: "",
      Bleed: "",
      Sleep: "",
      Rage: "",
      Tiredness: "",
      Blessing: "",
      SystemInvincibility: "",
      BestState: "",
      Invincibility: "",
      Suction: "",
      Taming: "",
      Curse: "",
      Flash: "",
      Runaway: "",
      MagicalExplosion: "",
      Sick: "",
      Malgravity: "",
      Dispel: "",
      Inversion: "",
      Mineralization: "",
      NoTools: "",
      Enhance: "",
      ComboInvincibility: "",
      DeathTorqueShot: "",
      SystemAddHate: "",
      Recovery: ""
    },
    SkillAttackType: {
      selfName: "",
      None: "",
      Physical: "",
      Magic: "",
      SkillNormal: ""
    },
    SkillComboType: {
      selfName: "",
      None: "",
      Start: "",
      Rengeki: "",
      ThirdEye: "",
      Filling: "",
      Quick: "",
      HardHit: "",
      Tenacity: "",
      Invincible: "",
      BloodSucking: "",
      Tough: "",
      AMomentaryWalk: "",
      Reflection: "",
      Illusion: "",
      Max: ""
    }
  },
};

export default dictionary;
